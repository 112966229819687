import { baseApi as api } from "./base.api";
export const addTagTypes = [
  "projects",
  "users",
  "Credit",
  "applications",
  "auth",
  "logout",
  "verification",
  "configurations",
  "accounts",
  "profile",
  "file",
  "pages",
  "notes",
  "transactions",
  "seo-report",
  "seo-page",
  "seo-page-term",
  "Ptt",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      projectsControllerCreate: build.mutation<
        ProjectsControllerCreate,
        ProjectsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/projects`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["projects"],
      }),
      projectsControllerFindAll: build.query<
        ProjectsControllerFindAll,
        ProjectsControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/projects`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["projects"],
      }),
      projectsControllerFindOne: build.query<
        ProjectsControllerFindOne,
        ProjectsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/projects/${queryArg}` }),
        providesTags: ["projects"],
      }),
      projectsControllerUpdate: build.mutation<
        ProjectsControllerUpdate,
        ProjectsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateProjectDto,
        }),
        invalidatesTags: ["projects"],
      }),
      projectsControllerRemove: build.mutation<
        ProjectsControllerRemove,
        ProjectsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg}`,
          method: "DELETE",
        }),
        invalidatesTags: ["projects"],
      }),
      projectsControllerGenerateNewApiKey: build.query<
        ProjectsControllerGenerateNewApiKey,
        ProjectsControllerGenerateNewApiKeyApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg}/generate-api-key`,
        }),
        providesTags: ["projects"],
      }),
      usersProjectsControllerFindAll: build.query<
        UsersProjectsControllerFindAll,
        UsersProjectsControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.uid}/projects`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["projects", "users"],
      }),
      usersProjectsControllerFindOne: build.query<
        UsersProjectsControllerFindOne,
        UsersProjectsControllerFindOneApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.uid}/projects/${queryArg.id}`,
        }),
        providesTags: ["projects", "users"],
      }),
      usersProjectsControllerGenerateNewApiKey: build.query<
        UsersProjectsControllerGenerateNewApiKey,
        UsersProjectsControllerGenerateNewApiKeyApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.uid}/projects/${queryArg.id}/generate-api-key`,
        }),
        providesTags: ["projects", "users"],
      }),
      creditControllerAddFirstBalance: build.mutation<
        CreditControllerAddFirstBalance,
        CreditControllerAddFirstBalanceApiArg
      >({
        query: () => ({ url: `/Credit/first-balance`, method: "POST" }),
        invalidatesTags: ["Credit"],
      }),
      creditControllerBalance: build.query<
        CreditControllerBalance,
        CreditControllerBalanceApiArg
      >({
        query: () => ({ url: `/Credit/balance` }),
        providesTags: ["Credit"],
      }),
      creditControllerTransactions: build.query<
        CreditControllerTransactions,
        CreditControllerTransactionsApiArg
      >({
        query: () => ({ url: `/Credit/transactions` }),
        providesTags: ["Credit"],
      }),
      applicationsControllerNewToken: build.query<
        ApplicationsControllerNewToken,
        ApplicationsControllerNewTokenApiArg
      >({
        query: (queryArg) => ({ url: `/applications/${queryArg}/new-token` }),
        providesTags: ["applications"],
      }),
      applicationsControllerCreate: build.mutation<
        ApplicationsControllerCreate,
        ApplicationsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/applications`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["applications"],
      }),
      applicationsControllerFindAll: build.query<
        ApplicationsControllerFindAll,
        ApplicationsControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/applications`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["applications"],
      }),
      applicationsControllerFindOne: build.query<
        ApplicationsControllerFindOne,
        ApplicationsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/applications/${queryArg}` }),
        providesTags: ["applications"],
      }),
      applicationsControllerUpdate: build.mutation<
        ApplicationsControllerUpdate,
        ApplicationsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/applications/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateApplicationDto,
        }),
        invalidatesTags: ["applications"],
      }),
      applicationsControllerDelete: build.mutation<
        ApplicationsControllerDelete,
        ApplicationsControllerDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/applications/${queryArg}`,
          method: "DELETE",
        }),
        invalidatesTags: ["applications"],
      }),
      projectsApplicationsControllerFindAllByProjectId: build.query<
        ProjectsApplicationsControllerFindAllByProjectId,
        ProjectsApplicationsControllerFindAllByProjectIdApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.id}/applications`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["applications"],
      }),
      authControllerRegister: build.mutation<
        AuthControllerRegister,
        AuthControllerRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/register`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerLogin: build.mutation<
        AuthControllerLogin,
        AuthControllerLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/login`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerRefresh: build.mutation<
        AuthControllerRefresh,
        AuthControllerRefreshApiArg
      >({
        query: () => ({ url: `/auth/refresh`, method: "POST" }),
        invalidatesTags: ["auth"],
      }),
      authControllerUnVerifiedAccounts: build.query<
        AuthControllerUnVerifiedAccounts,
        AuthControllerUnVerifiedAccountsApiArg
      >({
        query: () => ({ url: `/auth/verify` }),
        providesTags: ["auth"],
      }),
      authControllerResendVerify: build.mutation<
        AuthControllerResendVerify,
        AuthControllerResendVerifyApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/verify`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerVerify: build.mutation<
        AuthControllerVerify,
        AuthControllerVerifyApiArg
      >({
        query: () => ({ url: `/auth/verify`, method: "POST" }),
        invalidatesTags: ["auth"],
      }),
      authControllerRecovery: build.mutation<
        AuthControllerRecovery,
        AuthControllerRecoveryApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/recovery`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerReset: build.mutation<
        AuthControllerReset,
        AuthControllerResetApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/reset`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerLogout: build.mutation<
        AuthControllerLogout,
        AuthControllerLogoutApiArg
      >({
        query: () => ({ url: `/auth/logout`, method: "POST" }),
        invalidatesTags: ["auth", "logout"],
      }),
      authControllerChangePassword: build.mutation<
        AuthControllerChangePassword,
        AuthControllerChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/change-password`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerAccountInfo: build.query<
        AuthControllerAccountInfo,
        AuthControllerAccountInfoApiArg
      >({
        query: () => ({ url: `/auth/account/info` }),
        providesTags: ["auth"],
      }),
      authControllerUpdateAccountInfo: build.mutation<
        AuthControllerUpdateAccountInfo,
        AuthControllerUpdateAccountInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/account/info`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerRoleDrop: build.mutation<
        AuthControllerRoleDrop,
        AuthControllerRoleDropApiArg
      >({
        query: () => ({ url: `/auth/role-drop`, method: "PATCH" }),
        invalidatesTags: ["auth"],
      }),
      authControllerDeactivate: build.mutation<
        AuthControllerDeactivate,
        AuthControllerDeactivateApiArg
      >({
        query: () => ({ url: `/auth/deactivate`, method: "PATCH" }),
        invalidatesTags: ["auth"],
      }),
      authControllerSessions: build.query<
        AuthControllerSessions,
        AuthControllerSessionsApiArg
      >({
        query: () => ({ url: `/auth/sessions` }),
        providesTags: ["auth"],
      }),
      authControllerLoginActivity: build.query<
        AuthControllerLoginActivity,
        AuthControllerLoginActivityApiArg
      >({
        query: () => ({ url: `/auth/login-activity` }),
        providesTags: ["auth"],
      }),
      authControllerDrop: build.mutation<
        AuthControllerDrop,
        AuthControllerDropApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/drop/${queryArg}`,
          method: "DELETE",
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerSetUsername: build.mutation<
        AuthControllerSetUsername,
        AuthControllerSetUsernameApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/username`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      authControllerBan: build.mutation<
        AuthControllerBan,
        AuthControllerBanApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/ban`,
          method: "DELETE",
          params: {
            id: queryArg.id,
            ttlSecond: queryArg.ttlSecond,
          },
        }),
        invalidatesTags: ["auth"],
      }),
      tokenControllerGenerateToken: build.mutation<
        TokenControllerGenerateToken,
        TokenControllerGenerateTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/token/create`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      tokenControllerDecodeToken: build.mutation<
        TokenControllerDecodeToken,
        TokenControllerDecodeTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/token/decode`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      tokenControllerValidateTokenAsUser: build.query<
        TokenControllerValidateTokenAsUser,
        TokenControllerValidateTokenAsUserApiArg
      >({
        query: () => ({ url: `/auth/token/validate-as-user` }),
        providesTags: ["auth"],
      }),
      tokenControllerValidateTokenAsClient: build.query<
        TokenControllerValidateTokenAsClient,
        TokenControllerValidateTokenAsClientApiArg
      >({
        query: () => ({ url: `/auth/token/validate-as-client` }),
        providesTags: ["auth"],
      }),
      walletControllerAuthenticate: build.mutation<
        WalletControllerAuthenticate,
        WalletControllerAuthenticateApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/wallet`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      walletControllerGetMetamaskLoginMessage: build.query<
        WalletControllerGetMetamaskLoginMessage,
        WalletControllerGetMetamaskLoginMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/wallet/metamaskloginmessage`,
          params: {
            wallet: queryArg,
          },
        }),
        providesTags: ["auth"],
      }),
      walletControllerDisconnectWallet: build.mutation<
        WalletControllerDisconnectWallet,
        WalletControllerDisconnectWalletApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/wallet/disconnect`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      walletControllerConnectWallet: build.mutation<
        WalletControllerConnectWallet,
        WalletControllerConnectWalletApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/wallet/connect`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["auth"],
      }),
      verificationControllerVerify: build.mutation<
        VerificationControllerVerify,
        VerificationControllerVerifyApiArg
      >({
        query: (queryArg) => ({
          url: `/verification`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["verification"],
      }),
      configurationControllerCreate: build.mutation<
        ConfigurationControllerCreate,
        ConfigurationControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/configurations`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["configurations"],
      }),
      configurationControllerFindAll: build.query<
        ConfigurationControllerFindAll,
        ConfigurationControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/configurations`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            project: queryArg.project,
            roles: queryArg.roles,
          },
        }),
        providesTags: ["configurations"],
      }),
      configurationControllerFindOne: build.query<
        ConfigurationControllerFindOne,
        ConfigurationControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/configurations/${queryArg}` }),
        providesTags: ["configurations"],
      }),
      configurationControllerUpdate: build.mutation<
        ConfigurationControllerUpdate,
        ConfigurationControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/configurations/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateConfigurationDto,
        }),
        invalidatesTags: ["configurations"],
      }),
      configurationControllerDelete: build.mutation<
        ConfigurationControllerDelete,
        ConfigurationControllerDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/configurations/${queryArg}`,
          method: "DELETE",
        }),
        invalidatesTags: ["configurations"],
      }),
      accountsControllerAdd: build.mutation<
        AccountsControllerAdd,
        AccountsControllerAddApiArg
      >({
        query: (queryArg) => ({
          url: `/users/accounts`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["accounts"],
      }),
      accountsControllerFind: build.query<
        AccountsControllerFind,
        AccountsControllerFindApiArg
      >({
        query: (queryArg) => ({
          url: `/users/accounts`,
          params: {
            provider: queryArg.provider,
            identity: queryArg.identity,
            isVerified: queryArg.isVerified,
          },
        }),
        providesTags: ["accounts"],
      }),
      accountsControllerUpdate: build.mutation<
        AccountsControllerUpdate,
        AccountsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/users/accounts`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["accounts"],
      }),
      accountsControllerRemove: build.mutation<
        AccountsControllerRemove,
        AccountsControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/users/accounts`,
          method: "DELETE",
          body: queryArg,
        }),
        invalidatesTags: ["accounts"],
      }),
      accountsControllerSetPrimary: build.mutation<
        AccountsControllerSetPrimary,
        AccountsControllerSetPrimaryApiArg
      >({
        query: (queryArg) => ({
          url: `/users/accounts/set-primary`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["accounts"],
      }),
      usersControllerAddFirstBalance: build.mutation<
        UsersControllerAddFirstBalance,
        UsersControllerAddFirstBalanceApiArg
      >({
        query: () => ({ url: `/users/first-balance`, method: "POST" }),
        invalidatesTags: ["users"],
      }),
      usersControllerCreate: build.mutation<
        UsersControllerCreate,
        UsersControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/users`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["users"],
      }),
      usersControllerFindAll: build.query<
        UsersControllerFindAll,
        UsersControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/users`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["users"],
      }),
      usersControllerFindOne: build.query<
        UsersControllerFindOne,
        UsersControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/users/${queryArg}` }),
        providesTags: ["users"],
      }),
      usersControllerUpdate: build.mutation<
        UsersControllerUpdate,
        UsersControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateUserDto,
        }),
        invalidatesTags: ["users"],
      }),
      usersControllerDelete: build.mutation<
        UsersControllerDelete,
        UsersControllerDeleteApiArg
      >({
        query: (queryArg) => ({ url: `/users/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["users"],
      }),
      usersControllerChangeUserRole: build.mutation<
        UsersControllerChangeUserRole,
        UsersControllerChangeUserRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/users/change-role/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateRolesDto,
        }),
        invalidatesTags: ["users"],
      }),
      usersControllerChangePassword: build.mutation<
        UsersControllerChangePassword,
        UsersControllerChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}/change-password`,
          method: "PATCH",
          body: queryArg.updatePasswordDto,
        }),
        invalidatesTags: ["users"],
      }),
      profileControllerGetUserAvatar: build.query<
        ProfileControllerGetUserAvatar,
        ProfileControllerGetUserAvatarApiArg
      >({
        query: () => ({ url: `/profile/avatar` }),
        providesTags: ["profile"],
      }),
      profileControllerUploadUserAvatar: build.mutation<
        ProfileControllerUploadUserAvatar,
        ProfileControllerUploadUserAvatarApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/avatar`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["profile"],
      }),
      profileControllerFindPublicOne: build.query<
        ProfileControllerFindPublicOne,
        ProfileControllerFindPublicOneApiArg
      >({
        query: (queryArg) => ({ url: `/profile/public/${queryArg}` }),
        providesTags: ["profile"],
      }),
      profileControllerGetUserDetailsWithAdmin: build.query<
        ProfileControllerGetUserDetailsWithAdmin,
        ProfileControllerGetUserDetailsWithAdminApiArg
      >({
        query: (queryArg) => ({ url: `/profile/${queryArg}/admin` }),
        providesTags: ["profile"],
      }),
      profileControllerSearch: build.query<
        ProfileControllerSearch,
        ProfileControllerSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/search`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["profile"],
      }),
      profileControllerFindAll: build.query<
        ProfileControllerFindAll,
        ProfileControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/profile`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["profile"],
      }),
      profileControllerFindOne: build.query<
        ProfileControllerFindOne,
        ProfileControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/profile/${queryArg}` }),
        providesTags: ["profile"],
      }),
      fileControllerUpload: build.mutation<
        FileControllerUpload,
        FileControllerUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/file/upload`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["file"],
      }),
      pagesControllerCreate: build.mutation<
        PagesControllerCreate,
        PagesControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pages`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["pages"],
      }),
      pagesControllerFindAll: build.query<
        PagesControllerFindAll,
        PagesControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/pages`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["pages"],
      }),
      pagesControllerFindOne: build.query<
        PagesControllerFindOne,
        PagesControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/pages/${queryArg}` }),
        providesTags: ["pages"],
      }),
      pagesControllerUpdate: build.mutation<
        PagesControllerUpdate,
        PagesControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/pages/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updatePageDto,
        }),
        invalidatesTags: ["pages"],
      }),
      pagesControllerDelete: build.mutation<
        PagesControllerDelete,
        PagesControllerDeleteApiArg
      >({
        query: (queryArg) => ({ url: `/pages/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["pages"],
      }),
      notesControllerGetTagCloud: build.query<
        NotesControllerGetTagCloud,
        NotesControllerGetTagCloudApiArg
      >({
        query: () => ({ url: `/notes/tag-cloud` }),
        providesTags: ["notes"],
      }),
      notesControllerGetTagCloudByCategory: build.query<
        NotesControllerGetTagCloudByCategory,
        NotesControllerGetTagCloudByCategoryApiArg
      >({
        query: (queryArg) => ({ url: `/notes/tag-cloud/${queryArg}` }),
        providesTags: ["notes"],
      }),
      notesControllerFindOneBySlug: build.query<
        NotesControllerFindOneBySlug,
        NotesControllerFindOneBySlugApiArg
      >({
        query: (queryArg) => ({
          url: `/notes/by-slug/${queryArg.slug}/${queryArg.domain}`,
        }),
        providesTags: ["notes"],
      }),
      notesControllerGetDraft: build.query<
        NotesControllerGetDraft,
        NotesControllerGetDraftApiArg
      >({
        query: () => ({ url: `/notes/draft` }),
        providesTags: ["notes"],
      }),
      notesControllerFileUpload: build.mutation<
        NotesControllerFileUpload,
        NotesControllerFileUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/notes/${queryArg.note}/files`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notes"],
      }),
      notesControllerFileUpdate: build.mutation<
        NotesControllerFileUpdate,
        NotesControllerFileUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/notes/${queryArg.note}/files/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateNoteFileDto,
        }),
        invalidatesTags: ["notes"],
      }),
      notesControllerFindAllByCategory: build.query<
        NotesControllerFindAllByCategory,
        NotesControllerFindAllByCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/notes/by-category/${queryArg.category}`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["notes"],
      }),
      notesControllerFindAllByTag: build.query<
        NotesControllerFindAllByTag,
        NotesControllerFindAllByTagApiArg
      >({
        query: (queryArg) => ({
          url: `/notes/by-tag/${queryArg.tag}`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["notes"],
      }),
      notesControllerCreate: build.mutation<
        NotesControllerCreate,
        NotesControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/notes`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["notes"],
      }),
      notesControllerFindAll: build.query<
        NotesControllerFindAll,
        NotesControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/notes`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            sortOrder: queryArg.sortOrder,
            sortBy: queryArg.sortBy,
            search: queryArg.search,
            filters: queryArg.filters,
          },
        }),
        providesTags: ["notes"],
      }),
      notesControllerUpsert: build.mutation<
        NotesControllerUpsert,
        NotesControllerUpsertApiArg
      >({
        query: (queryArg) => ({ url: `/notes`, method: "PUT", body: queryArg }),
        invalidatesTags: ["notes"],
      }),
      notesControllerFindOne: build.query<
        NotesControllerFindOne,
        NotesControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/notes/${queryArg}` }),
        providesTags: ["notes"],
      }),
      notesControllerUpdate: build.mutation<
        NotesControllerUpdate,
        NotesControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/notes/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateNoteDto,
        }),
        invalidatesTags: ["notes"],
      }),
      notesControllerDelete: build.mutation<
        NotesControllerDelete,
        NotesControllerDeleteApiArg
      >({
        query: (queryArg) => ({ url: `/notes/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["notes"],
      }),
      transactionsControllerGet: build.query<
        TransactionsControllerGet,
        TransactionsControllerGetApiArg
      >({
        query: (queryArg) => ({ url: `/transactions/get/${queryArg}` }),
        providesTags: ["transactions"],
      }),
      transactionsControllerComplete: build.mutation<
        TransactionsControllerComplete,
        TransactionsControllerCompleteApiArg
      >({
        query: (queryArg) => ({
          url: `/transactions/complete/${queryArg}`,
          method: "POST",
        }),
        invalidatesTags: ["transactions"],
      }),
      transactionsControllerCheck: build.query<
        TransactionsControllerCheck,
        TransactionsControllerCheckApiArg
      >({
        query: (queryArg) => ({ url: `/transactions/check/${queryArg}` }),
        providesTags: ["transactions"],
      }),
      transactionsControllerCreate: build.mutation<
        TransactionsControllerCreate,
        TransactionsControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/transactions`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["transactions"],
      }),
      transactionsControllerFindAll: build.query<
        TransactionsControllerFindAll,
        TransactionsControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/transactions`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            roles: queryArg.roles,
          },
        }),
        providesTags: ["transactions"],
      }),
      transactionsControllerFindOne: build.query<
        TransactionsControllerFindOne,
        TransactionsControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/transactions/${queryArg}` }),
        providesTags: ["transactions"],
      }),
      transactionsControllerUpdate: build.mutation<
        TransactionsControllerUpdate,
        TransactionsControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/transactions/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateTransactionDto,
        }),
        invalidatesTags: ["transactions"],
      }),
      transactionsControllerDelete: build.mutation<
        TransactionsControllerDelete,
        TransactionsControllerDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/transactions/${queryArg}`,
          method: "DELETE",
        }),
        invalidatesTags: ["transactions"],
      }),
      seoControllerAnalyse: build.mutation<
        SeoControllerAnalyse,
        SeoControllerAnalyseApiArg
      >({
        query: (queryArg) => ({
          url: `/seo/analyse`,
          method: "POST",
          body: queryArg,
        }),
      }),
      seoControllerGet: build.query<SeoControllerGet, SeoControllerGetApiArg>({
        query: (queryArg) => ({ url: `/seo/reports/${queryArg}` }),
        providesTags: ["seo-report"],
      }),
      seoControllerForce: build.mutation<
        SeoControllerForce,
        SeoControllerForceApiArg
      >({
        query: (queryArg) => ({ url: `/seo/force/${queryArg}`, method: "PUT" }),
        invalidatesTags: ["seo-report"],
      }),
      seoControllerGetWorkspace: build.query<
        SeoControllerGetWorkspace,
        SeoControllerGetWorkspaceApiArg
      >({
        query: () => ({ url: `/seo/workspace` }),
      }),
      seoControllerGetPageData: build.query<
        SeoControllerGetPageData,
        SeoControllerGetPageDataApiArg
      >({
        query: (queryArg) => ({ url: `/seo/page/${queryArg}` }),
        providesTags: ["seo-page"],
      }),
      seoControllerGetPageTermData: build.query<
        SeoControllerGetPageTermData,
        SeoControllerGetPageTermDataApiArg
      >({
        query: (queryArg) => ({ url: `/seo/page-term/${queryArg}` }),
        providesTags: ["seo-page-term"],
      }),
      pttControllerPtt: build.mutation<
        PttControllerPtt,
        PttControllerPttApiArg
      >({
        query: (queryArg) => ({ url: `/ptt/${queryArg}/ptt`, method: "PATCH" }),
        invalidatesTags: ["Ptt"],
      }),
      pttControllerGetBarcode: build.query<
        PttControllerGetBarcode,
        PttControllerGetBarcodeApiArg
      >({
        query: (queryArg) => ({ url: `/ptt/${queryArg}/barcode` }),
        providesTags: ["Ptt"],
      }),
      pttControllerCreate: build.mutation<
        PttControllerCreate,
        PttControllerCreateApiArg
      >({
        query: (queryArg) => ({ url: `/ptt`, method: "POST", body: queryArg }),
        invalidatesTags: ["Ptt"],
      }),
      pttControllerFindAll: build.query<
        PttControllerFindAll,
        PttControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/ptt`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
            search: queryArg.search,
            status: queryArg.status,
            role: queryArg.role,
            type: queryArg["type"],
            project: queryArg.project,
            roles: queryArg.roles,
          },
        }),
        providesTags: ["Ptt"],
      }),
      pttControllerFindOne: build.query<
        PttControllerFindOne,
        PttControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/ptt/${queryArg}` }),
        providesTags: ["Ptt"],
      }),
      pttControllerUpdate: build.mutation<
        PttControllerUpdate,
        PttControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/ptt/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updatePttDto,
        }),
        invalidatesTags: ["Ptt"],
      }),
      pttControllerDelete: build.mutation<
        PttControllerDelete,
        PttControllerDeleteApiArg
      >({
        query: (queryArg) => ({ url: `/ptt/${queryArg}`, method: "DELETE" }),
        invalidatesTags: ["Ptt"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as coreApi };
export type ProjectsControllerCreate = /** status 201  */ OkDto;
export type ProjectsControllerCreateApiArg = CreateProjectDto;
export type ProjectsControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: ProjectDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type ProjectsControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type ProjectsControllerFindOne = /** status 200  */ ProjectDto;
export type ProjectsControllerFindOneApiArg = string;
export type ProjectsControllerUpdate = /** status 200  */ ProjectDto;
export type ProjectsControllerUpdateApiArg = {
  id: string;
  updateProjectDto: UpdateProjectDto;
};
export type ProjectsControllerRemove = /** status 200  */ string;
export type ProjectsControllerRemoveApiArg = string;
export type ProjectsControllerGenerateNewApiKey = /** status 200  */ ApiKeyDto;
export type ProjectsControllerGenerateNewApiKeyApiArg = string;
export type UsersProjectsControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: ProjectDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type UsersProjectsControllerFindAllApiArg = {
  uid: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type UsersProjectsControllerFindOne = /** status 200  */ ProjectDto;
export type UsersProjectsControllerFindOneApiArg = {
  uid: string;
  id: string;
};
export type UsersProjectsControllerGenerateNewApiKey =
  /** status 200  */ ApiKeyDto;
export type UsersProjectsControllerGenerateNewApiKeyApiArg = {
  uid: string;
  id: string;
};
export type CreditControllerAddFirstBalance = /** status 200  */
  | ResponseStatusDto
  | /** status 201  */ ResponseStatusDto;
export type CreditControllerAddFirstBalanceApiArg = void;
export type CreditControllerBalance = /** status 200  */ number;
export type CreditControllerBalanceApiArg = void;
export type CreditControllerTransactions = /** status 200  */ CreditDto;
export type CreditControllerTransactionsApiArg = void;
export type ApplicationsControllerNewToken = /** status 200  */ SeoBotDto;
export type ApplicationsControllerNewTokenApiArg = string;
export type ApplicationsControllerCreate = /** status 201  */ ResponseIdDto;
export type ApplicationsControllerCreateApiArg = CreateApplicationDto;
export type ApplicationsControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: ApplicationDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type ApplicationsControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type ApplicationsControllerFindOne = /** status 200  */ ApplicationDto;
export type ApplicationsControllerFindOneApiArg = string;
export type ApplicationsControllerUpdate = /** status 200  */ ResponseIdDto;
export type ApplicationsControllerUpdateApiArg = {
  id: string;
  updateApplicationDto: UpdateApplicationDto;
};
export type ApplicationsControllerDelete = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type ApplicationsControllerDeleteApiArg = string;
export type ProjectsApplicationsControllerFindAllByProjectId =
  /** status 200  */ PaginatedDto & {
    list?: ApplicationDto[];
    meta?: PaginatedMetaDto;
    parent?: any;
  };
export type ProjectsApplicationsControllerFindAllByProjectIdApiArg = {
  id: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type AuthControllerRegister = /** status 201  User created!  */ AuthDto;
export type AuthControllerRegisterApiArg = RegisterDto;
export type AuthControllerLogin = /** status 200  */ AuthDto;
export type AuthControllerLoginApiArg = LoginDto;
export type AuthControllerRefresh = /** status 201  */ AuthDto;
export type AuthControllerRefreshApiArg = void;
export type AuthControllerUnVerifiedAccounts =
  /** status 200 Account list for verification. */ Account[];
export type AuthControllerUnVerifiedAccountsApiArg = void;
export type AuthControllerResendVerify =
  /** status 200 Verification trigger */ OkDto;
export type AuthControllerResendVerifyApiArg = ResendVerifyDto;
export type AuthControllerVerify = /** status 200   */
  | AuthDto
  | /** status 201  */ AuthDto;
export type AuthControllerVerifyApiArg = void;
export type AuthControllerRecovery = /** status 200   */
  | ResponseMessageDto
  | /** status 201  */ OkDto;
export type AuthControllerRecoveryApiArg = RecoveryDto;
export type AuthControllerReset = /** status 200  */
  | ResetResponseDto
  | /** status 201  */ ResetResponseDto;
export type AuthControllerResetApiArg = ResetPasswordDto;
export type AuthControllerLogout = /** status 201  */ Logout;
export type AuthControllerLogoutApiArg = void;
export type AuthControllerChangePassword =
  /** status 200  */ ResponseMessageDto;
export type AuthControllerChangePasswordApiArg = UpdatePasswordDto;
export type AuthControllerAccountInfo =
  /** status 200  object of user information.  */ AccountInfoDto;
export type AuthControllerAccountInfoApiArg = void;
export type AuthControllerUpdateAccountInfo = /** status 200  */
  | AccountInfoDto
  | /** status 201  object of user information.  */ AccountInfoDto;
export type AuthControllerUpdateAccountInfoApiArg = UpdateUserDto;
export type AuthControllerRoleDrop =
  /** status 200  RoleEnum updated successfully.  */ OkDto;
export type AuthControllerRoleDropApiArg = void;
export type AuthControllerDeactivate =
  /** status 200  RoleEnum updated successfully.  */ OkDto;
export type AuthControllerDeactivateApiArg = void;
export type AuthControllerSessions = /** status 200   */ UserSessionDto[];
export type AuthControllerSessionsApiArg = void;
export type AuthControllerLoginActivity = /** status 200   */ UserSessionDto[];
export type AuthControllerLoginActivityApiArg = void;
export type AuthControllerDrop = /** status 200  */ DropSessionDto;
export type AuthControllerDropApiArg = string;
export type AuthControllerSetUsername = /** status 200  */ OkDto;
export type AuthControllerSetUsernameApiArg = UpdateUsernameDto;
export type AuthControllerBan = /** status 200  */ BanDto;
export type AuthControllerBanApiArg = {
  id: string;
  ttlSecond?: string;
};
export type TokenControllerGenerateToken = /** status 201  */ GenerateTokenDto;
export type TokenControllerGenerateTokenApiArg = GenerateTokenDto;
export type TokenControllerDecodeToken = /** status 201  */ GenerateTokenDto;
export type TokenControllerDecodeTokenApiArg = TokenDto;
export type TokenControllerValidateTokenAsUser = unknown;
export type TokenControllerValidateTokenAsUserApiArg = void;
export type TokenControllerValidateTokenAsClient = unknown;
export type TokenControllerValidateTokenAsClientApiArg = void;
export type WalletControllerAuthenticate =
  /** status 201 It returns WalletResponseDto */ AuthDto;
export type WalletControllerAuthenticateApiArg = WalletLoginDto;
export type WalletControllerGetMetamaskLoginMessage =
  /** status 200 It returns Metamask Login Message String */ WalletLoginMessageDto;
export type WalletControllerGetMetamaskLoginMessageApiArg = string;
export type WalletControllerDisconnectWallet =
  /** status 200 It returns success message */
    | WalletLoginMessageDto
    | /** status 201  */ WalletLoginMessageDto;
export type WalletControllerDisconnectWalletApiArg = SocialDisconnectDto;
export type WalletControllerConnectWallet =
  /** status 201 It returns WalletResponseDto */ WalletLoginMessageDto;
export type WalletControllerConnectWalletApiArg = WalletLoginDto;
export type VerificationControllerVerify = /** status 200  */
  | VerifyResponseDto
  | /** status 201  */ VerifyResponseDto;
export type VerificationControllerVerifyApiArg = VerifyDto;
export type ConfigurationControllerCreate = /** status 201  */ ResponseIdDto;
export type ConfigurationControllerCreateApiArg = CreateConfigurationDto;
export type ConfigurationControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: ConfigurationDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type ConfigurationControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  /** The unique id */
  project?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type ConfigurationControllerFindOne =
  /** status 200  */ ConfigurationDto;
export type ConfigurationControllerFindOneApiArg = string;
export type ConfigurationControllerUpdate = /** status 200  */ ResponseIdDto;
export type ConfigurationControllerUpdateApiArg = {
  id: string;
  updateConfigurationDto: UpdateConfigurationDto;
};
export type ConfigurationControllerDelete = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type ConfigurationControllerDeleteApiArg = string;
export type AccountsControllerAdd = /** status 201  */ AccountDto;
export type AccountsControllerAddApiArg = AddAccountDto;
export type AccountsControllerFind = /** status 200  */ AccountDto[];
export type AccountsControllerFindApiArg = {
  provider: string;
  identity: string;
  isVerified: boolean;
};
export type AccountsControllerUpdate = /** status 200  */ OkDto;
export type AccountsControllerUpdateApiArg = AccountDto;
export type AccountsControllerRemove = /** status 200  */ OkDto;
export type AccountsControllerRemoveApiArg = AccountDto;
export type AccountsControllerSetPrimary = /** status 200  */ AccountDto[];
export type AccountsControllerSetPrimaryApiArg = AccountDto;
export type UsersControllerAddFirstBalance = /** status 200  */
  | ResponseStatusDto
  | /** status 201  */ ResponseStatusDto;
export type UsersControllerAddFirstBalanceApiArg = void;
export type UsersControllerCreate = /** status 201  */ ResponseIdDto;
export type UsersControllerCreateApiArg = CreateUserDto;
export type UsersControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: UserDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type UsersControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type UsersControllerFindOne = /** status 200  */ UserDto;
export type UsersControllerFindOneApiArg = string;
export type UsersControllerUpdate = /** status 200  */ ResponseIdDto;
export type UsersControllerUpdateApiArg = {
  id: string;
  updateUserDto: UpdateUserDto;
};
export type UsersControllerDelete = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type UsersControllerDeleteApiArg = string;
export type UsersControllerChangeUserRole =
  /** status 200  RoleEnum updated successfully.  */ OkDto;
export type UsersControllerChangeUserRoleApiArg = {
  id: string;
  updateRolesDto: UpdateRolesDto;
};
export type UsersControllerChangePassword =
  /** status 200 Password updated successfully.  */ OkDto;
export type UsersControllerChangePasswordApiArg = {
  id: string;
  updatePasswordDto: UpdatePasswordDto;
};
export type ProfileControllerGetUserAvatar =
  /** status 200  Avatar image  */ AvatarDto;
export type ProfileControllerGetUserAvatarApiArg = void;
export type ProfileControllerUploadUserAvatar =
  /** status 201  */ ResponseStatusDto;
export type ProfileControllerUploadUserAvatarApiArg = {
  /** The file to upload */
  file?: Blob;
};
export type ProfileControllerFindPublicOne =
  /** status 200  */ ProfilePublicDto;
export type ProfileControllerFindPublicOneApiArg = string;
export type ProfileControllerGetUserDetailsWithAdmin =
  /** status 200  object of user information.  */ ProfileDto;
export type ProfileControllerGetUserDetailsWithAdminApiArg = string;
export type ProfileControllerSearch = /** status 200  */ PaginatedDto & {
  list?: ProfileInfoDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type ProfileControllerSearchApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type ProfileControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: ProfileDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type ProfileControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type ProfileControllerFindOne = /** status 200  */ ProfileDto;
export type ProfileControllerFindOneApiArg = string;
export type FileControllerUpload = /** status 201 File uploaded! */ FileDto;
export type FileControllerUploadApiArg = {
  /** The file to upload */
  file?: Blob;
};
export type PagesControllerCreate = /** status 201  */ ResponseIdDto;
export type PagesControllerCreateApiArg = CreatePageDto;
export type PagesControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: PageDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type PagesControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type PagesControllerFindOne = /** status 200  */ PageDto;
export type PagesControllerFindOneApiArg = string;
export type PagesControllerUpdate = /** status 200  */ ResponseIdDto;
export type PagesControllerUpdateApiArg = {
  id: string;
  updatePageDto: UpdatePageDto;
};
export type PagesControllerDelete = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type PagesControllerDeleteApiArg = string;
export type NotesControllerGetTagCloud = /** status 200  */ TagCloudDto[];
export type NotesControllerGetTagCloudApiArg = void;
export type NotesControllerGetTagCloudByCategory =
  /** status 200  */ TagCloudDto[];
export type NotesControllerGetTagCloudByCategoryApiArg = string;
export type NotesControllerFindOneBySlug = /** status 200  */ NoteDto;
export type NotesControllerFindOneBySlugApiArg = {
  slug: string;
  domain: string;
};
export type NotesControllerGetDraft = /** status 200  */ NoteDto;
export type NotesControllerGetDraftApiArg = void;
export type NotesControllerFileUpload = /** status 200  */
  | ResponseStatusDto
  | /** status 201  */ ResponseStatusDto;
export type NotesControllerFileUploadApiArg = {
  note: string;
  body: {
    file?: Blob;
    exif?: {
      [key: string]: string;
    };
  };
};
export type NotesControllerFileUpdate = /** status 200  */ ResponseIdDto;
export type NotesControllerFileUpdateApiArg = {
  note: string;
  id: string;
  updateNoteFileDto: UpdateNoteFileDto;
};
export type NotesControllerFindAllByCategory =
  /** status 200  */ PaginatedDto & {
    list?: NoteDto[];
    meta?: PaginatedMetaDto;
    parent?: any;
  };
export type NotesControllerFindAllByCategoryApiArg = {
  category: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type NotesControllerFindAllByTag = /** status 200  */ PaginatedDto & {
  list?: NoteDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type NotesControllerFindAllByTagApiArg = {
  tag: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type NotesControllerCreate = /** status 201  */ ResponseIdDto;
export type NotesControllerCreateApiArg = CreateNoteDto;
export type NotesControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: NoteDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type NotesControllerFindAllApiArg = {
  /** Page number */
  page?: number;
  /** Number of items per page */
  limit?: number;
  /** Sort order direction */
  sortOrder?: "asc" | "desc";
  /** Field to sort by */
  sortBy?: string;
  search?: string;
  /** Filter criteria for the note */
  filters?: {
    [key: string]: any;
  };
};
export type NotesControllerUpsert = /** status 200  */ ResponseIdDto;
export type NotesControllerUpsertApiArg = UpsertNoteDto;
export type NotesControllerFindOne = /** status 200  */ NoteDto;
export type NotesControllerFindOneApiArg = string;
export type NotesControllerUpdate = /** status 200  */ ResponseIdDto;
export type NotesControllerUpdateApiArg = {
  id: string;
  updateNoteDto: UpdateNoteDto;
};
export type NotesControllerDelete = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type NotesControllerDeleteApiArg = string;
export type TransactionsControllerGet = /** status 200  */ TransactionPublicDto;
export type TransactionsControllerGetApiArg = string;
export type TransactionsControllerComplete = /** status 200  */
  | CompleteTransactionDto
  | /** status 201  */ CompleteTransactionDto;
export type TransactionsControllerCompleteApiArg = string;
export type TransactionsControllerCheck = /** status 200  */ TransactionDto;
export type TransactionsControllerCheckApiArg = string;
export type TransactionsControllerCreate = /** status 201  */ ResponseIdDto;
export type TransactionsControllerCreateApiArg = CreateTransactionDto;
export type TransactionsControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: TransactionDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type TransactionsControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type TransactionsControllerFindOne = /** status 200  */ TransactionDto;
export type TransactionsControllerFindOneApiArg = string;
export type TransactionsControllerUpdate = /** status 200  */ ResponseIdDto;
export type TransactionsControllerUpdateApiArg = {
  id: string;
  updateTransactionDto: UpdateTransactionDto;
};
export type TransactionsControllerDelete = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type TransactionsControllerDeleteApiArg = string;
export type SeoControllerAnalyse = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type SeoControllerAnalyseApiArg = CreateSeoReportDto;
export type SeoControllerGet = /** status 200  */ SeoReportDto;
export type SeoControllerGetApiArg = string;
export type SeoControllerForce = /** status 200  */ ResponseIdDto;
export type SeoControllerForceApiArg = string;
export type SeoControllerGetWorkspace = /** status 200  */ SeoWorkspaceDto;
export type SeoControllerGetWorkspaceApiArg = void;
export type SeoControllerGetPageData = /** status 200  */ SeoPageDto;
export type SeoControllerGetPageDataApiArg = string;
export type SeoControllerGetPageTermData = /** status 200  */ SeoPageTermDto;
export type SeoControllerGetPageTermDataApiArg = string;
export type PttControllerPtt = /** status 200  */ ResponseIdDto;
export type PttControllerPttApiArg = string;
export type PttControllerGetBarcode = /** status 200  */ BarcodeDto;
export type PttControllerGetBarcodeApiArg = string;
export type PttControllerCreate = /** status 201  */ ResponseIdDto;
export type PttControllerCreateApiArg = CreatePttDto;
export type PttControllerFindAll = /** status 200  */ PaginatedDto & {
  list?: PttDto[];
  meta?: PaginatedMetaDto;
  parent?: any;
};
export type PttControllerFindAllApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  role?: string;
  type?: string;
  /** The unique id */
  project?: string;
  roles?: (
    | "client"
    | "api"
    | "guest"
    | "newbie"
    | "needverify"
    | "emailless"
    | "phoneless"
    | "passwordless"
    | "user"
    | "editor"
    | "manager"
    | "admin"
    | "god"
  )[];
};
export type PttControllerFindOne = /** status 200  */ PttDto;
export type PttControllerFindOneApiArg = string;
export type PttControllerUpdate = /** status 200  */ ResponseIdDto;
export type PttControllerUpdateApiArg = {
  id: string;
  updatePttDto: UpdatePttDto;
};
export type PttControllerDelete = /** status 200  */
  | ResponseIdDto
  | /** status 201  */ ResponseIdDto;
export type PttControllerDeleteApiArg = string;
export type OkDto = {
  /** Successfull message */
  message: string;
};
export type ValidationErrorDto = {
  constraints: object;
  property: string;
  children?: ValidationErrorDto[];
};
export type ErrorDto = {
  status: number;
  name: string;
  message: string;
  timestamp?: string;
  method?: string;
  path?: string;
  stack?: string;
  validations: ValidationErrorDto[];
};
export type CreateProjectDto = {
  name: string;
};
export type PaginatedMetaDto = {
  page?: number;
  limit?: number;
  total?: number;
  item?: object;
};
export type PaginatedDto = {
  meta: PaginatedMetaDto;
  list: any[];
  parent?: object;
};
export type AvatarDto = {
  /** Image source */
  src: string;
  /** Avatar type */
  type: number;
};
export type Account = {
  provider: string;
  identity: string;
  isVerified: boolean;
};
export type User = {
  /** The name of the User */
  name: string;
  accounts: Account[];
  /** The password of the user. */
  password: string;
  /** All roles of the user. */
  roles: string[];
  /** The unique username of the User */
  username?: string;
  /** Determines user is verified or not. */
  isVerified: boolean;
  /** It corresponds user is active or not. */
  isDeactivated: boolean;
  createdAt: string;
  /** The unique id */
  id: string;
};
export type Profile = {
  avatar?: AvatarDto;
  /** The unique id */
  id: string;
  user: User;
  /** totalXp */
  totalXp: number;
  /** totalTicket */
  totalTicket: number;
};
export type ProjectDto = {
  name: string;
  /** The unique id */
  createdBy: Profile;
  /** The unique id */
  id: string;
};
export type UpdateProjectDto = {
  name: string;
};
export type ApiKeyDto = {
  key: string;
};
export type ResponseStatusDto = {
  /** Response status as boolean */
  status: boolean;
};
export type ObjectId = {};
export type Transaction = {
  createdAt: string;
  /** Activity definition. It should be unique. Use "ServiceName:method" pattern. */
  definition: string;
  project: ObjectId;
  app: ObjectId;
  /** The user id of request owner */
  user: ObjectId;
  /** The session key of request owner */
  session: string;
  /** The unique id */
  id: string;
  amount: number;
};
export type CreditDto = {
  /** The user id of request owner */
  user: ObjectId;
  /** Credit balance */
  balance: number;
  transactions?: Transaction[];
  /** The unique id */
  id: string;
};
export type SeoBotDto = {
  name: string;
  homepage: string;
  description: string;
  callbackUrl: string;
  token: string;
  tokenHash: string;
  status:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  createdBy: Profile;
  /** The unique id */
  id: string;
};
export type ResponseIdDto = {
  /** Response id as string */
  id: string | null;
};
export type CreateApplicationDto = {
  /** The unique id */
  project: ObjectId;
  name: string;
  homepage: string;
  description?: string;
  callbackUrl?: string;
};
export type ApplicationDto = {
  createdAt: string;
  updatedAt: string;
  /** The unique id */
  project: ObjectId;
  name: string;
  homepage: string;
  description?: string;
  callbackUrl?: string;
  token: string;
  tokenHash: string;
  /** Type of [app] */
  type?: "payment" | "oauth";
  status:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  createdBy: Profile;
  /** The unique id */
  id: string;
};
export type UpdateApplicationDto = {
  name: string;
  homepage: string;
  description?: string;
  callbackUrl?: string;
  status:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
};
export type AuthDto = {
  /** The name of the User */
  name: string;
  /** All roles of the user. */
  roles: string[];
  /** Session key */
  session: string;
  accessToken: string;
  refreshToken: string;
};
export type RegisterDto = {
  /** The name of the User */
  name: string;
  /** The password of the user. */
  password: string;
  /** The unique username of the User */
  username?: string;
  /** The email of the User */
  email?: string;
  /** The phone of the user */
  phone?: string;
  /** Invitation code */
  invitation?: string;
};
export type LoginDto = {
  /** The password of the user. */
  password: string;
  /** The username, email or sms number of the User */
  username: string;
  /** This is for extending the refresh token time. */
  remember: 0 | 1;
};
export type ResendVerifyDto = {
  identity: string;
};
export type ResponseMessageDto = {
  /** Response message */
  message: string;
};
export type RecoveryDto = {
  /** The email of the User */
  email?: string;
  /** The phone of the user */
  phone?: string;
};
export type ResetResponseDto = {
  /** The name of the User */
  name: string;
  /** All roles of the user. */
  roles: string[];
  /** Session key */
  session: string;
  accessToken: string;
  refreshToken: string;
};
export type ResetPasswordDto = {
  /** The password of the user. */
  password: string;
};
export type Logout = {
  /** Logout status */
  status: boolean;
  /** Logout message */
  message: string;
};
export type UpdatePasswordDto = {
  /** The password of the user. */
  password: string;
  /** The new password of the user. */
  newPassword: string;
};
export type AccountInfoDto = {
  /** The name of the User */
  name: string;
  accounts: Account[];
  /** All roles of the user. */
  roles: string[];
  /** The unique username of the User */
  username?: string;
  /** Determines user is verified or not. */
  isVerified: boolean;
  /** It corresponds user is active or not. */
  isDeactivated: boolean;
  createdAt: string;
};
export type UpdateUserDto = {
  /** The name of the User */
  name: string;
};
export type UserSessionDto = {
  /**  User device info associated with session  */
  device: object;
  /**  User browser associated with session  */
  browser: object;
  /**  User ip address associated with session  */
  ip: string;
  /** corresponds country which is the user connecting from */
  location: object;
  /** corresponds session is active or not */
  isActive: boolean;
  /**  user session id. */
  sessionKey: string;
};
export type DropSessionDto = {
  /** Logout status */
  status: boolean;
  /** Logout message */
  message: string;
};
export type UpdateUsernameDto = {
  /** The unique username of the User */
  username?: string;
};
export type BanDto = {
  /** Logout status */
  status: boolean;
  /** Logout message */
  message: string;
};
export type GenerateTokenDto = {
  /** id */
  id: string;
  /** session key */
  key: string;
  /** token type */
  type: "access" | "refresh" | "verify" | "recovery" | "api-key" | "app-token";
  /** payload */
  payload: object;
  expiresIn?: number;
};
export type TokenDto = {
  /** token */
  token: string;
};
export type WalletLoginDto = {
  /** Ref code */
  ref?: string;
  /** Ethereum account address */
  accountAddress: string;
  /** Signature for message */
  signature: string;
  /** Message for login */
  message: string;
};
export type WalletLoginMessageDto = {
  /** Message for login */
  message: string;
};
export type SocialDisconnectDto = {
  id: string;
};
export type VerifyResponseDto = {
  /** The verify token */
  token: string;
};
export type VerifyDto = {
  /** The data will be verify. Email or Phone */
  data: string;
  /** Verification code */
  code: string;
  /** Type of token */
  type: "recovery" | "verify";
};
export type CreateConfigurationDto = {
  /** The unique id */
  project: string;
  name: string;
  data?: string[][];
};
export type ConfigurationDto = {
  /** The unique id */
  project: string;
  name: string;
  data?: string[][];
  status:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  createdBy: Profile;
  /** The unique id */
  id: string;
};
export type UpdateConfigurationDto = {
  /** The unique id */
  project: string;
  name: string;
  data?: string[][];
};
export type AccountDto = {
  provider: string;
  identity: string;
  isVerified: boolean;
};
export type AddAccountDto = {
  provider: string;
  identity: string;
  isVerified: boolean;
};
export type CreateUserDto = {
  /** The name of the User */
  name: string;
  /** The password of the user. */
  password: string;
  /** The unique username of the User */
  username?: string;
  /** The email of the User */
  email?: string;
  /** The phone of the user */
  phone?: string;
  /** Project ID */
  project: string;
  /** Determines user is verified or not. */
  isVerified: boolean;
};
export type UserDto = {
  /** The name of the User */
  name: string;
  accounts: Account[];
  /** All roles of the user. */
  roles: string[];
  /** The unique username of the User */
  username?: string;
  /** Determines user is verified or not. */
  isVerified: boolean;
  /** It corresponds user is active or not. */
  isDeactivated: boolean;
  createdAt: string;
  /** The unique id */
  id: string;
};
export type UpdateRolesDto = {
  /** All roles of the user. */
  roles: string[];
};
export type UserPublicDto = {
  /** The name of the User */
  name: string;
  accounts: Account[];
  /** The unique username of the User */
  username?: string;
  /** Determines user is verified or not. */
  isVerified: boolean;
  /** It corresponds user is active or not. */
  isDeactivated: boolean;
  createdAt: string;
  /** The unique id */
  id: string;
};
export type ProfilePublicDto = {
  avatar?: AvatarDto;
  /** The unique id */
  id: string;
  user: UserPublicDto;
};
export type ProfileDto = {
  avatar?: AvatarDto;
  /** The unique id */
  id: string;
  user: UserDto;
};
export type UserInfoDto = {
  /** The name of the User */
  name: string;
  /** The unique username of the User */
  username?: string;
  createdAt: string;
};
export type ProfileInfoDto = {
  avatar?: AvatarDto;
  /** The unique id */
  id: string;
  user: UserInfoDto;
};
export type FileDto = {
  /** File source */
  src: string;
  /** File type */
  type: string;
  id?: string;
};
export type CreatePageDto = {
  /** The unique id */
  app: ObjectId;
  title: string;
  slug: string;
  markdown?: string;
};
export type PageDto = {
  /** The unique id */
  app: ObjectId;
  title: string;
  slug: string;
  markdown?: string;
  /** The unique id */
  id: string;
};
export type UpdatePageDto = {
  /** The unique id */
  app?: ObjectId;
  title?: string;
  slug?: string;
  markdown?: string;
};
export type TagCloudDto = {
  name: string;
  usage: number;
};
export type Exif = {
  Model: string;
  Lens: string;
  ISOSpeedRatings: string;
  FocalLength: string;
  FNumber: string;
  ExposureTime: string;
  WhiteBalance: string;
  MeteringMode: string;
  DateTime: string;
  GPSLatitude: string;
  GPSLongitude: string;
};
export type Point = {
  type: string;
  coordinates: number[];
};
export type NoteFile = {
  src: string;
  type?: string;
  /** The unique id */
  id: string;
  altText: string;
  exif?: Exif;
  location?: Point;
};
export type PartialTypeClass = {
  createdAt?: string;
  updatedAt?: string;
  /** The unique id */
  project?: ObjectId;
  domain?: string;
  categories?: string[];
  title?: string;
  slug?: string;
  markdown?: string;
  files?: NoteFile[];
  tags?: string[];
  type?: "section" | "category" | "page" | "note" | "content";
  status?:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  createdBy?: Profile;
  /** The unique id */
  id?: string;
};
export type NoteDto = {
  createdAt: string;
  updatedAt: string;
  /** The unique id */
  project: ObjectId;
  domain: string;
  title: string;
  slug: string;
  markdown?: string;
  files?: NoteFile[];
  tags?: string[];
  type?: "section" | "category" | "page" | "note" | "content";
  status?:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  createdBy: Profile;
  /** The unique id */
  id: string;
  categories?: PartialTypeClass[];
};
export type UpdateNoteFileDto = {
  altText: string;
  location?: Point;
};
export type CreateNoteDto = {
  /** The unique id */
  project: ObjectId;
  domain: string;
  categories: string[];
  title: string;
  slug: string;
  markdown?: string;
  tags?: string[];
  type?: "section" | "category" | "page" | "note" | "content";
  status?:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  createdBy: Profile;
};
export type UpsertNoteDto = {
  domain: string;
  categories: string[];
  title: string;
  slug: string;
  markdown?: string;
  files?: NoteFile[];
  tags?: string[];
  type?: "section" | "category" | "page" | "note" | "content";
  status?:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  id?: string;
};
export type UpdateFileDto = {
  /** The unique id */
  id: string;
  altText: string;
  location?: Point;
};
export type UpdateNoteDto = {
  domain?: string;
  categories?: string[];
  title?: string;
  slug?: string;
  markdown?: string;
  tags?: string[];
  type?: "section" | "category" | "page" | "note" | "content";
  status?:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  files?: UpdateFileDto[];
};
export type TransactionLog = {
  address: string;
  topics: string[];
  data: string;
  blockNumber: string;
  blockHash: string;
  timeStamp: string;
  gasPrice: string;
  gasUsed: string;
  logIndex: string;
  transactionHash: string;
  transactionIndex: string;
};
export type TransactionPublicDto = {
  /** The unique key to prevent duplicate transaction record for the same process. */
  uniqueKey: string;
  title?: string;
  address: string;
  amount: number;
  balance: number;
  log?: TransactionLog[];
  status: string;
  /** The unique id */
  id: string;
  application: ApplicationDto;
  tokenAddress: string;
  chainId: string;
};
export type CompleteTransactionDto = {
  remainingAmount: number;
};
export type TransactionDto = {
  /** The unique id */
  application: ObjectId;
  /** The unique key to prevent duplicate transaction record for the same process. */
  uniqueKey: string;
  title?: string;
  tokenAddress?: string;
  chainId?: string;
  address: string;
  amount: number;
  balance: number;
  log?: TransactionLog[];
  status: string;
  /** The unique id */
  id: string;
};
export type CreateTransactionDto = {
  /** The unique key to prevent duplicate transaction record for the same process. */
  uniqueKey: string;
  title?: string;
  tokenAddress?: string;
  chainId?: string;
  amount: number;
};
export type UpdateTransactionDto = {
  status: string;
};
export type CreateSeoReportDto = {
  type: "page" | "page-term" | "compare-page" | "compare-page-term";
  dependencies?: string[];
  urls?: string[];
  terms?: string[];
};
export type SeoUrl = {
  url: string;
  /** The unique id */
  id: string;
};
export type SeoSearchTerm = {
  term: string;
  /** The unique id */
  id: string;
};
export type SeoJobDto = {
  service: string;
  method: string;
  params?: string[][];
  type: string;
  status:
    | "queued"
    | "pending"
    | "running"
    | "paused"
    | "stopped"
    | "success"
    | "failure"
    | "retrying"
    | "waiting-dependencies";
  startedAt: string;
  completedAt: string;
  failedAt: string;
  retryingAt: string;
  retryCount: number;
  maxRetries: number;
  error: string;
  dependencies: string[];
  /** The unique id */
  id: string;
};
export type SeoReportDto = {
  createdAt: string;
  updatedAt: string;
  type: "page" | "page-term" | "compare-page" | "compare-page-term";
  dependencies?: string[];
  /** The unique id */
  id: string;
  url: SeoUrl;
  term: SeoSearchTerm;
  pipeline: SeoJobDto;
};
export type SeoWorkspaceDto = {
  createdAt: string;
  updatedAt: string;
  user: string;
  lastReports: SeoReportDto[];
  savedReports: SeoReportDto[];
  /** The unique id */
  id: string;
};
export type UrlDetail = {
  domain: string;
  tld: string;
  subdomain: string;
  pathname: string;
  query: string;
  hash: string;
  depth: number;
  length: number;
};
export type Meta = {
  tag: string;
  name: string;
  property: string;
  content: string;
  httpEquiv: string;
  charset: string;
};
export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};
export type Heading = {
  tag: string;
  position: Rect;
  role: Rect;
  level: number;
  innerHTML: string;
  fontSize: number;
  fontWeight: number;
  fontFamily: string;
  fontColor: string;
  backgroundColor: string;
  contrastRatio: number;
  hasHTML: boolean;
  isVisible: boolean;
};
export type Link = {
  title: string;
  href: string;
  ariaLabel: string;
  ariaLabelledByTextContent: string;
  ariaHidden: boolean;
  target: string;
  tabIndex: number;
  isKeyboardAccessible: boolean;
  position: Rect;
  role: Rect;
  tag: string;
  rel: string;
  innerHTML: string;
  fontSize: number;
  fontWeight: number;
  fontFamily: string;
  fontColor: string;
  backgroundColor: string;
  contrastRatio: number;
  hasHTML: boolean;
  isVisible: boolean;
  /** Type of link (internal, external, anchor, etc.) */
  type:
    | "internal"
    | "external"
    | "anchor"
    | "email"
    | "phone"
    | "download"
    | "javascript"
    | "unknown";
};
export type ContrastIssue = {
  selector: string;
  elementTextSnippet: string;
  fontColor: string;
  backgroundColor: string;
  contrastRatio: number;
  wcagLevelFail: ("AA" | "AAA" | null) | null;
};
export type EngagementFeatures = {
  /** Indicates if a comment section is present on the page */
  commentSectionPresent: boolean;
  /** Indicates if social sharing buttons are present on the page */
  socialSharingButtonsPresent: boolean;
  /** Count of video embeds found on the page */
  videoEmbedsCount: number;
};
export type SeoUrlDto = {
  url: string;
  /** The unique id */
  id: string;
};
export type SeoPageDto = {
  createdAt: string;
  updatedAt: string;
  title: string;
  urlDetails: UrlDetail;
  meta: Meta[];
  headings: Heading[];
  links: Link[];
  content: string;
  contrastIssues: ContrastIssue[];
  fleschReadingEase: number;
  fleschKincaidGradeLevel: number;
  smogGrade: number;
  gunningFogScore: number;
  colemanLiauIndex: number;
  automatedReadabilityIndex: number;
  daleChallReadabilityScore: number;
  wordCount: number;
  sentenceCount: number;
  paragraphCount: number;
  averageWordsPerSentence: number;
  averageSentenceLength: number;
  longSentencesCount: number;
  complexWordRatio: number;
  contentSimilarityToCompetitorAverage: number;
  viewportMetaTagPresent: boolean;
  urlDepth: number;
  urlLength: number;
  urlHasKeywords: boolean;
  htmlSizeBytes: number;
  engagementFeatures: EngagementFeatures;
  suggestedKeywords: string[];
  /** The unique id */
  id: string;
  url: SeoUrlDto;
};
export type UrlAnalysis = {
  termInSlug: boolean;
  length: number;
  termInPath: boolean;
  termInDomain: boolean;
  termInSubdomain: boolean;
  depth: number;
};
export type NlpAnalysis = {
  name: string;
  termPresent: boolean;
  termCount: number;
  termLocation: string;
  termDensityScore: number;
  sentiment: string;
  semantic_relatedness_score: number;
  prominent_keyphrases: string[];
  tfidf_score: number;
  has_cta: boolean;
};
export type HeadingAnalysis = {
  h1: NlpAnalysis;
  relevantSubheadingsCount: number;
  headingHierarchyAnalysis: string;
};
export type SeoPageTermDto = {
  createdAt: string;
  updatedAt: string;
  url: UrlAnalysis;
  title: NlpAnalysis;
  meta: NlpAnalysis[];
  headings: HeadingAnalysis;
  content: NlpAnalysis;
  /** The unique id */
  id: string;
};
export type BarcodeDto = {
  barcode: string;
};
export type CreatePttDto = {
  /** The unique id */
  project: string;
  name: string;
  barcode: string;
  data?: {
    [key: string]: string;
  };
};
export type PttDto = {
  /** The unique id */
  project: string;
  name: string;
  barcode: string;
  status:
    | "draft"
    | "hidden"
    | "pending"
    | "scheduled"
    | "paused"
    | "active"
    | "completed"
    | "published"
    | "canceled";
  /** The unique id */
  createdBy: Profile;
  /** The unique id */
  id: string;
  data?: {
    [key: string]: string;
  };
};
export type UpdatePttDto = {
  name: string;
  data?: {
    [key: string]: string;
  };
};
export const {
  useProjectsControllerCreateMutation,
  useProjectsControllerFindAllQuery,
  useLazyProjectsControllerFindAllQuery,
  useProjectsControllerFindOneQuery,
  useLazyProjectsControllerFindOneQuery,
  useProjectsControllerUpdateMutation,
  useProjectsControllerRemoveMutation,
  useProjectsControllerGenerateNewApiKeyQuery,
  useLazyProjectsControllerGenerateNewApiKeyQuery,
  useUsersProjectsControllerFindAllQuery,
  useLazyUsersProjectsControllerFindAllQuery,
  useUsersProjectsControllerFindOneQuery,
  useLazyUsersProjectsControllerFindOneQuery,
  useUsersProjectsControllerGenerateNewApiKeyQuery,
  useLazyUsersProjectsControllerGenerateNewApiKeyQuery,
  useCreditControllerAddFirstBalanceMutation,
  useCreditControllerBalanceQuery,
  useLazyCreditControllerBalanceQuery,
  useCreditControllerTransactionsQuery,
  useLazyCreditControllerTransactionsQuery,
  useApplicationsControllerNewTokenQuery,
  useLazyApplicationsControllerNewTokenQuery,
  useApplicationsControllerCreateMutation,
  useApplicationsControllerFindAllQuery,
  useLazyApplicationsControllerFindAllQuery,
  useApplicationsControllerFindOneQuery,
  useLazyApplicationsControllerFindOneQuery,
  useApplicationsControllerUpdateMutation,
  useApplicationsControllerDeleteMutation,
  useProjectsApplicationsControllerFindAllByProjectIdQuery,
  useLazyProjectsApplicationsControllerFindAllByProjectIdQuery,
  useAuthControllerRegisterMutation,
  useAuthControllerLoginMutation,
  useAuthControllerRefreshMutation,
  useAuthControllerUnVerifiedAccountsQuery,
  useLazyAuthControllerUnVerifiedAccountsQuery,
  useAuthControllerResendVerifyMutation,
  useAuthControllerVerifyMutation,
  useAuthControllerRecoveryMutation,
  useAuthControllerResetMutation,
  useAuthControllerLogoutMutation,
  useAuthControllerChangePasswordMutation,
  useAuthControllerAccountInfoQuery,
  useLazyAuthControllerAccountInfoQuery,
  useAuthControllerUpdateAccountInfoMutation,
  useAuthControllerRoleDropMutation,
  useAuthControllerDeactivateMutation,
  useAuthControllerSessionsQuery,
  useLazyAuthControllerSessionsQuery,
  useAuthControllerLoginActivityQuery,
  useLazyAuthControllerLoginActivityQuery,
  useAuthControllerDropMutation,
  useAuthControllerSetUsernameMutation,
  useAuthControllerBanMutation,
  useTokenControllerGenerateTokenMutation,
  useTokenControllerDecodeTokenMutation,
  useTokenControllerValidateTokenAsUserQuery,
  useLazyTokenControllerValidateTokenAsUserQuery,
  useTokenControllerValidateTokenAsClientQuery,
  useLazyTokenControllerValidateTokenAsClientQuery,
  useWalletControllerAuthenticateMutation,
  useWalletControllerGetMetamaskLoginMessageQuery,
  useLazyWalletControllerGetMetamaskLoginMessageQuery,
  useWalletControllerDisconnectWalletMutation,
  useWalletControllerConnectWalletMutation,
  useVerificationControllerVerifyMutation,
  useConfigurationControllerCreateMutation,
  useConfigurationControllerFindAllQuery,
  useLazyConfigurationControllerFindAllQuery,
  useConfigurationControllerFindOneQuery,
  useLazyConfigurationControllerFindOneQuery,
  useConfigurationControllerUpdateMutation,
  useConfigurationControllerDeleteMutation,
  useAccountsControllerAddMutation,
  useAccountsControllerFindQuery,
  useLazyAccountsControllerFindQuery,
  useAccountsControllerUpdateMutation,
  useAccountsControllerRemoveMutation,
  useAccountsControllerSetPrimaryMutation,
  useUsersControllerAddFirstBalanceMutation,
  useUsersControllerCreateMutation,
  useUsersControllerFindAllQuery,
  useLazyUsersControllerFindAllQuery,
  useUsersControllerFindOneQuery,
  useLazyUsersControllerFindOneQuery,
  useUsersControllerUpdateMutation,
  useUsersControllerDeleteMutation,
  useUsersControllerChangeUserRoleMutation,
  useUsersControllerChangePasswordMutation,
  useProfileControllerGetUserAvatarQuery,
  useLazyProfileControllerGetUserAvatarQuery,
  useProfileControllerUploadUserAvatarMutation,
  useProfileControllerFindPublicOneQuery,
  useLazyProfileControllerFindPublicOneQuery,
  useProfileControllerGetUserDetailsWithAdminQuery,
  useLazyProfileControllerGetUserDetailsWithAdminQuery,
  useProfileControllerSearchQuery,
  useLazyProfileControllerSearchQuery,
  useProfileControllerFindAllQuery,
  useLazyProfileControllerFindAllQuery,
  useProfileControllerFindOneQuery,
  useLazyProfileControllerFindOneQuery,
  useFileControllerUploadMutation,
  usePagesControllerCreateMutation,
  usePagesControllerFindAllQuery,
  useLazyPagesControllerFindAllQuery,
  usePagesControllerFindOneQuery,
  useLazyPagesControllerFindOneQuery,
  usePagesControllerUpdateMutation,
  usePagesControllerDeleteMutation,
  useNotesControllerGetTagCloudQuery,
  useLazyNotesControllerGetTagCloudQuery,
  useNotesControllerGetTagCloudByCategoryQuery,
  useLazyNotesControllerGetTagCloudByCategoryQuery,
  useNotesControllerFindOneBySlugQuery,
  useLazyNotesControllerFindOneBySlugQuery,
  useNotesControllerGetDraftQuery,
  useLazyNotesControllerGetDraftQuery,
  useNotesControllerFileUploadMutation,
  useNotesControllerFileUpdateMutation,
  useNotesControllerFindAllByCategoryQuery,
  useLazyNotesControllerFindAllByCategoryQuery,
  useNotesControllerFindAllByTagQuery,
  useLazyNotesControllerFindAllByTagQuery,
  useNotesControllerCreateMutation,
  useNotesControllerFindAllQuery,
  useLazyNotesControllerFindAllQuery,
  useNotesControllerUpsertMutation,
  useNotesControllerFindOneQuery,
  useLazyNotesControllerFindOneQuery,
  useNotesControllerUpdateMutation,
  useNotesControllerDeleteMutation,
  useTransactionsControllerGetQuery,
  useLazyTransactionsControllerGetQuery,
  useTransactionsControllerCompleteMutation,
  useTransactionsControllerCheckQuery,
  useLazyTransactionsControllerCheckQuery,
  useTransactionsControllerCreateMutation,
  useTransactionsControllerFindAllQuery,
  useLazyTransactionsControllerFindAllQuery,
  useTransactionsControllerFindOneQuery,
  useLazyTransactionsControllerFindOneQuery,
  useTransactionsControllerUpdateMutation,
  useTransactionsControllerDeleteMutation,
  useSeoControllerAnalyseMutation,
  useSeoControllerGetQuery,
  useLazySeoControllerGetQuery,
  useSeoControllerForceMutation,
  useSeoControllerGetWorkspaceQuery,
  useLazySeoControllerGetWorkspaceQuery,
  useSeoControllerGetPageDataQuery,
  useLazySeoControllerGetPageDataQuery,
  useSeoControllerGetPageTermDataQuery,
  useLazySeoControllerGetPageTermDataQuery,
  usePttControllerPttMutation,
  usePttControllerGetBarcodeQuery,
  useLazyPttControllerGetBarcodeQuery,
  usePttControllerCreateMutation,
  usePttControllerFindAllQuery,
  useLazyPttControllerFindAllQuery,
  usePttControllerFindOneQuery,
  useLazyPttControllerFindOneQuery,
  usePttControllerUpdateMutation,
  usePttControllerDeleteMutation,
} = injectedRtkApi;
