export const Logo = ({
  className,
  isCompact = false,
}: {
  className?: string;
  isCompact?: boolean;
}) => (
  <svg
    x="0"
    y="0"
    shapeRendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox={isCompact ? "0 0 200 200" : "0 0 1000 200"}
    xmlSpace="preserve"
    className={className}
  >
    <defs>
      <linearGradient id="logo-gradient" x1="0%" y1="0%" x2="300%" y2="0%">
        <stop offset="0%" stopColor="#14B8A6">
          <animate
            attributeName="stop-color"
            values="#14B8A6; #A855F7; #F97316"
            dur="4s"
            repeatCount="indefinite"
          />
        </stop>
        {/*<stop offset='50%' stopColor='#A855F7' />*/}
        <stop offset="100%" stopColor="#F97316">
          <animate
            attributeName="stop-color"
            values="#F97316; #A855F7; #14B8A6"
            dur="4s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
      <clipPath id="logo-clip-path">
        <path d="M282.3,54c-9.9,0-18.7,3.4-25.9,9v-9l-15.3-4.4v45.4c0,22.7,18.5,41.2,41.2,41.2s41.2-18.3,41.2-41-18.5-41.2-41.2-41.2ZM282.1,120.9c-14.3,0-25.7-11.5-25.7-25.7s11.5-25.7,25.7-25.7,25.7,11.5,25.7,25.7-11.5,25.7-25.7,25.7Z" />
        <path d="M400.1,54v9c-7-5.6-16.1-9-25.9-9-22.9,0-41.2,18.3-41.2,41.2s18.5,41.2,41.2,41.2,18.7-3.4,25.9-9v9l15.3-4V58.2l-15.3-4.2ZM374,120.9c-14.3,0-25.7-11.5-25.7-25.7s11.5-25.7,25.7-25.7,25.7,11.5,25.7,25.7-11.5,25.7-25.7,25.7Z" />
        <path d="M490.4,87.7h-49.3v-.4c4.4-13.5,18.9-20.9,32.4-16.5,1.2.4,2.6,1,3.6,1.6h22.7c-5-7.4-12.3-13.3-21.5-16.3-21.5-7.2-44.8,4.6-51.9,26.1-.6,1.8-1,3.6-1.4,5.2-1,5.2-.8,10.5,0,15.3h65c-4.4,13.5-18.9,20.9-32.4,16.5-1,0-1.8-.6-2.6-1h-23.1c5,7.2,12.3,12.9,21.1,15.7,21.7,7,44.8-4.8,51.9-26.5,1.2-4,2-8,2-12.1s-.2-5.4-.6-8.2l-16.1.6h.2Z" />
        <path d="M557.8,54c-22.7,0-41.2,18.5-41.2,41.2s18.5,41.2,41.2,41.2,26.7-7.2,34.2-18.1h-22.7c-3.4,1.8-7.4,2.8-11.7,2.8-14.3,0-25.7-11.5-25.7-25.7s11.5-25.7,25.7-25.7,21.3,7.6,24.5,18.1h-32.4l5,15.3h43.2c.4-2.4.8-5,.8-7.6s-.2-5.2-.8-7.6c-3.2-19.3-20.1-33.8-40.2-33.8h0Z" />
        <path d="M649.7,54c-22.7,0-41.2,18.3-41.2,41.2v41.2l10.3,4.4v-18.5c7.6,8.6,18.7,14.1,31,14.1,22.7,0,41.2-18.5,41.2-41.2s-18.5-41.2-41.2-41.2ZM649.7,126.2c-17.1,0-31-13.9-31-31s13.9-31,31-31,31,13.9,31,31-13.9,31-31,31Z" />
        <path d="M772.5,53.8v14.1c-7.6-8.6-18.7-14.1-31-14.1-22.7,0-41.2,18.5-41.2,41.2s18.5,41.2,41.2,41.2,23.5-5.4,31-14.1v14.1l10.3-4V57.8l-10.3-4ZM741.6,126.2c-17.1,0-31-13.9-31-31s13.9-31,31-31,31,13.9,31,31-13.9,31-31,31h0Z" />
        <path d="M834.7,54c-22.9,0-41.2,18.3-41.2,41.2h0v37.2l10.3,4h0v-41.2,1-1c0-17.1,13.9-31,31-31s24.7,8.4,29,20.3l9.9-3.2c-5.6-15.9-20.9-27.3-38.8-27.3Z" />
        <polygon points="922.8 54 922.8 54 912.5 54 912.5 54 876.5 54 876.5 64.2 912.5 64.2 912.5 128.4 922.8 136.2 922.8 136.2 922.8 64.2 950.9 64.2 959 54 959 54 922.8 54" />
      </clipPath>
    </defs>
    <rect
      width="1000"
      height="200"
      clipPath="url(#logo-clip-path)"
      className="fill-[#3F3F46] dark:fill-[url(#logo-gradient)]"
    />
    <g className="group-data-[state=collapsed]:opacity-100 opacity-0 transition-all duration-500 ease-in-out delay-300">

      <circle fill="none" cx="100.5" cy="101.8" r="29.9" transform="translate(-41.4 83.4) rotate(-38)" />
      <path fill="#222221"
        style={{ filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, .5))' }}
        d="M100.7,22c-18.9,0-36.3,6.6-50,17.6v-17.7s-29.7-11.9-29.7-11.9v91.6h0s0,0,0,0c0,44,35.7,79.7,79.7,79.7s79.7-35.7,79.7-79.7S144.7,22,100.7,22ZM100.5,151.6c-27.5,0-49.8-22.3-49.8-49.8s22.3-49.8,49.8-49.8,49.8,22.3,49.8,49.8-22.3,49.8-49.8,49.8Z" />
      <path fill="#f1efd7"
        style={{ filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, .3))' }}
        d="M100.5,52c-27.4,0-49.7,22.2-49.8,49.6h0v.2s0,0,0,0,0,0,0,0v79.3s20,8.9,20,8.9v-48.3c8.3,6.2,18.6,9.9,29.8,9.9,27.5,0,49.8-22.3,49.8-49.8s-22.3-49.8-49.8-49.8ZM100.5,131.7c-16.5,0-29.9-13.4-29.9-29.9h0c0-16.5,13.4-29.9,29.9-29.9s29.9,13.4,29.9,29.9-13.4,29.9-29.9,29.9Z" />

    </g>
  </svg>
);
