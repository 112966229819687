import {
  AppLayout,
  LandingLayout,
  SecureLayout,
  SystemLayout
} from "@/layout";
import { ErrorView } from "@/views/error/Error.view";
import { createBrowserRouter } from "react-router-dom";

const routes = createBrowserRouter([
  {
    element: <SystemLayout />,
    errorElement: <ErrorView />,
    children: [
      {
        element: <LandingLayout />,
        children: [
          {
            index: true,
            lazy: () => import("./views/landing/Landing.view"),
            // lazy: () => import("./views/statics/search/Search.view"),
          },
          {
            path: "register",
            lazy: () => import("./views/auth/Register.view"),
          },
          {
            path: "login",
            lazy: () => import("./views/auth/Login.view"),
          },
          {
            path: "verify",
            lazy: () => import("./views/auth/Verify.view"),
          },
          {
            path: "recovery",
            lazy: () => import("./views/auth/Recovery.view"),
          },
          {
            path: "reset",
            lazy: () => import("./views/auth/Reset.view"),
          },
          {
            path: "logout",
            lazy: () => import("./views/auth/Logout.view"),
          },
        ],
      },
      {
        element: <LandingLayout />,
        children: [
          {
            path: "about",
            lazy: () => import("./views/statics/about/About.view"),
          },
          {
            path: "howto",
            lazy: () => import("./views/statics/howto/HowTo.view"),
          },
          {
            path: "services",
            lazy: () => import("./views/statics/services/Services.view"),
          },
          {
            path: "contact",
            lazy: () => import("./views/statics/contact/Contact.view"),
          },
          {
            path: "privacy",
            lazy: () => import("./views/statics/privacy/PrivacyPolicy.view"),
          },
          {
            path: "tos",
            lazy: () => import("./views/statics/tos/TermsAndConditions.view"),
          },
          {
            path: "cookies",
            lazy: () => import("./views/statics/cookies/CookiePolicy.view"),
          },
        ],
      },
      {
        element: <AppLayout />,
        children: [
          {
            element: <SecureLayout />,
            children: [
              {
                path: "dashboard/*",
                lazy: () => import("./views/dashboard/Dashboard.view"),
              },
              {
                path: "reports/*",
                lazy: () => import("./views/reports/routes.view"),
              },
              {
                path: 'settings/*',
                lazy: () => import('./views/settings/Settings.view')
              },
              // {
              //   path: "*",
              //   element: <ErrorView status={404} />,
              // },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <ErrorView status={404} />,
      },
    ],
  },
]);

export default routes;
